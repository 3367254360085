<template>
  <header class="header-global">
    <base-nav
      class="navbar-main"
      transparent
      type=""
      effect="light"
      expand
    >
      <router-link
        slot="brand"
        class="navbar-brand mr-lg-5"
        to="/"
      >
        <img
          src="@/assets/arelav_white_small.png"
          alt="logo"
        >
      </router-link>

      <div
        class="row"
        slot="content-header"
        slot-scope="{closeMenu}"
      >
        <div class="col-6 collapse-brand">
          <a href="/">
            <img src="@/assets/arelav_blue_small.png">
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu" />
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <base-dropdown
          class="nav-item"
          menu-classes="dropdown-menu-xl"
        >
          <a
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
            role="button"
          >
            <i class="ni ni-ui-04 d-lg-none" />
            <span class="nav-link-inner--text">Servicios</span>
          </a>
          <div class="dropdown-menu-inner">
            <a
              href="/tags"
              class="media d-flex align-items-center"
            >
              <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                <i class="ni ni-tag" />
              </div>
              <div class="media-body ml-3">
                <h6 class="heading text-primary mb-md-1">Servicios con Tags</h6>
                <p class="description d-none d-md-inline-block mb-0">Marketing personalizado, Control de accesos y Seguimiento a Operaciones utilizando QR, Beacons o NFC.</p>
              </div>
            </a>
            <a
              href="/restaurantes"
              class="media d-flex align-items-center"
            >
              <div class="icon icon-shape bg-gradient-success rounded-circle text-white">
                <i class="ni ni-map-big" />
              </div>
              <div class="media-body ml-3">
                <h5 class="heading text-success mb-md-1">Servicios para Restauarantes</h5>
                <p class="description d-none d-md-inline-block mb-0">Soluciones de Menús QR, toma de órdenes digitales y pago sin cotnacto.</p>
              </div>
            </a>
            <a
              href="/processes"
              class="media d-flex align-items-center"
            >
              <div class="icon icon-shape bg-gradient-warning rounded-circle text-white">
                <i class="ni ni-ui-04" />
              </div>
              <div class="media-body ml-3">
                <h5 class="heading text-warning mb-md-1">Control de Procesos</h5>
                <p class="description d-none d-md-inline-block mb-0">Soluciones de apoyo a la definición y Seguimiento a procesos empresariales (incluyendo NOM25).</p>
              </div>
            </a>
            <a
              href="/apps"
              class="media d-flex align-items-center"
            >
              <div class="icon icon-shape bg-gradient-info rounded-circle text-white">
                <i class="ni ni-mobile-button" />
              </div>
              <div class="media-body ml-3">
                <h5 class="heading text-info mb-md-1">Apps Development</h5>
                <p class="description d-none d-md-inline-block mb-0">Desarrollos a la medida de soluciones empresariales y aplicaciones móviles.</p>
              </div>
            </a>
          </div>
        </base-dropdown>
        <base-dropdown
          tag="li"
          class="nav-item"
        >
          <a
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
            role="button"
          >
            <i class="ni ni-collection d-lg-none" />
            <span class="nav-link-inner--text">Detalles</span>
          </a>
          <router-link
            to="/contact"
            class="dropdown-item"
          >
            Contacto
          </router-link>
          <router-link
            to="/login"
            class="dropdown-item"
          >
            Login
          </router-link>
          <router-link
            to="/register"
            class="dropdown-item"
          >
            Register
          </router-link>
        </base-dropdown>
      </ul>
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.facebook.com/BetterMenu-App-103698124956081/"
            target="_blank"
            rel="noopener"
            data-toggle="tooltip"
            title="Like us on Facebook"
          >
            <i class="fa fa-facebook-square" />
            <span class="nav-link-inner--text d-lg-none">Facebook</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.instagram.com/bettermenu.app"
            target="_blank"
            rel="noopener"
            data-toggle="tooltip"
            title="Follow us on Instagram"
          >
            <i class="fa fa-instagram" />
            <span class="nav-link-inner--text d-lg-none">Instagram</span>
          </a>
        </li>
        <li class="nav-item d-none d-lg-block ml-lg-4">
          <a
            href="/login"
            target="_blank"
            rel="noopener"
            class="btn btn-neutral btn-icon"
          >
            <span class="btn-inner-icon">
              <i class="fa fa-inbox mr-2" />
            </span>
            <span class="nav-link-inner--text">Ingresar</span>
          </a>
        </li>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from '@/components/BaseNav'
import BaseDropdown from '@/components/BaseDropdown'
import CloseButton from '@/components/CloseButton'

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  }
}
</script>
<style>
</style>
