






import { Component, Vue } from 'vue-property-decorator'
import ArelavPage from '@/views/arelav/ArelavPage.vue'

@Component({
  components: {
    ArelavPage
  }
})
export default class Home extends Vue {
  content = {
    sections: [
      {
        type: 'HEROTOP',
        hero: {
          title: 'Arelav.com',
          subtitle: 'Soluciones Empresariales y Aplicaciones Móviles',
          detail: 'Usa tags QR, NFC o beacons para dar seguimiento a tu procesos, controlar accesos o hacer marketing personalizado.',
          action: {
            title: 'Contáctanos',
            destination: 'mailto:ventas@arelav.com?subject=Solicitud%20de%20Detalles'
          },
          info: {
            title: 'Más detalle',
            destination: '/contact'
          }
        }
      },
      {
        type: '4CARDS',
        cards: [
          {
            icon: 'ni ni-tag',
            style: 'primary',
            title: 'Soluciones con Tags',
            details: 'Marketing personalizado, Control de accesos y Seguimiento a Operaciones utilizando QR, Beacons o NFC.',
            tags: ['#QR', '#NFC', '#Beacons'],
            action: 'Más ...',
            destination: '/tags'
          },
          {
            icon: 'ni ni-map-big',
            style: 'success',
            title: 'Restauarantes',
            details: 'Soluciones de Menús QR, toma de órdenes digitales y pago sin cotnacto.',
            tags: ['#QR', '#PagosSinContacto', '#Menús'],
            action: 'Más ...',
            destination: '/restaurantes'
          },
          {
            icon: 'ni ni-ui-04',
            style: 'warning',
            title: 'Control de Procesos',
            details: 'Soluciones de apoyo a la definición y Seguimiento a procesos empresariales (incluyendo NOM25).',
            tags: ['#Procesos', '#Calidad', '#NOM25'],
            action: 'Más ...',
            destination: '/processes'
          },
          {
            icon: 'ni ni-mobile-button',
            style: 'info',
            title: 'Apps Development',
            details: 'Desarrollos a la medida de soluciones empresariales y aplicaciones móviles.',
            tags: ['#iOS', '#Android', '#Desarrollos', '#BusinessIntelligence'],
            action: 'Más ...',
            destination: '/apps'
          }

        ]
      }
    ]
  }
}
