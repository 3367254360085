<template>
  <footer class="footer has-cards">
    <div class="container container-lg">
      <div class="row">
        <div class="col-md-3 mb-5 mb-md-0">
          <div class="card card-lift--hover shadow border-0">
            <router-link
              to="/tags"
              title="Servicios con Tags"
            >
              <img
                v-lazy="'img/services/hero_tags_small.jpg'"
                class="card-img"
              >
            </router-link>
          </div>
        </div>
        <div class="col-md-3 mb-5 mb-md-0">
          <div class="card card-lift--hover shadow border-0">
            <router-link
              to="/restuarantes"
              title="Servicios para Restaurantes"
            >
              <img
                v-lazy="'img/services/hero_restaurante_small.jpg'"
                class="card-img"
              >
            </router-link>
          </div>
        </div>
        <div class="col-md-3 mb-5 mb-md-0">
          <div class="card card-lift--hover shadow border-0">
            <router-link
              to="/processes"
              title="Control de Procesos"
            >
              <img
                v-lazy="'img/services/hero_processes_small.jpg'"
                class="card-img"
              >
            </router-link>
          </div>
        </div>
        <div class="col-md-3 mb-5 mb-lg-0">
          <div class="card card-lift--hover shadow border-0">
            <router-link
              to="/apps"
              title="Apps Development"
            >
              <img
                v-lazy="'img/services/hero_apps_small.jpg'"
                class="card-img"
              >
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row row-grid align-items-center my-md">
        <div class="col-lg-6">
          <h3 class="text-primary font-weight-light mb-2">
            Gracias por tu interés
          </h3>
          <h4 class="mb-0 font-weight-light">
            Contáctanos para conocer precios y más detalles.
          </h4>
        </div>
        <div class="col-lg-6 text-lg-center btn-wrapper">
          <a
            target="_blank"
            rel="noopener"
            href="mailto:ventas@arelav.com?subject=Solicitud%20de%20Detalles"
            class="btn btn-neutral btn-github btn-round btn-lg"
            data-toggle="tooltip"
            data-original-title="Mail us"
          >
            <i class="fa fa-envelope" />
            Más información
          </a>
        </div>
      </div>
      <hr>
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-6">
          <div class="copyright">
            &copy; {{ year }}
            <a
              href="mailto:info@arelav.com"
              target="_blank"
              rel="noopener"
            >Diseño Arelav.com</a>
          </div>
        </div>
        <div class="col-md-6">
          <ul class="nav nav-footer justify-content-end">
            <li class="nav-item">
              <a
                href="/contact"
                class="nav-link"
                target="_blank"
                rel="noopener"
              >Datos de Contacto</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'AppFooter',
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>
<style>
</style>
