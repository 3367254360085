import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Arelav from '../views/arelav/Arelav.vue'
import Contact from '../views/Contact.vue'
import Home from '../views/Home.vue'
import Portal from '../views/Portal.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Arelav',
    component: Arelav
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/portal',
    name: 'Portal',
    component: Portal
  },
  {
    path: '/vue',
    name: 'VueHome',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
